import "./AddVault.css";
import { useState } from "react";
import CancelVaultButton from "./CancelVaultButton/CancelVaultButton";
import CreateVaultButton from "./CreateVaultButton/CreateVaultButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDollar,
  faStopwatch,
  faAddressBook,
} from "@fortawesome/free-solid-svg-icons";

export default function AddVault() {
  // state variables for form input values
  const [amount, setAmount] = useState("");
  const [recipient, setRecipient] = useState("");
  const [date, setDate] = useState("");

  // handler for form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted:", { amount, recipient });
  };

  // handler for input value changes
  const handleInputChange = (event) => {
    console.log("input", event);
    const { name, value } = event.target;
    if (name === "amount") {
      setAmount(value);
    } else if (name === "recipient") {
      setRecipient(value);
    } else if (name === "date") {
      setDate(value);
    }
  };

  // icons
  const dollarIcon = <FontAwesomeIcon icon={faDollar} />;
  const addressBookIcon = <FontAwesomeIcon icon={faAddressBook} />;
  const stopwatchIcon = <FontAwesomeIcon icon={faStopwatch} />;

  return (
    <>
      <div className="add-vault-container">
        <h2>Add Vault</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-field-container">
            <div className="form-field-square icon-add-amount">
              {dollarIcon}
            </div>
            <input
              className="form-field"
              placeholder="Amount"
              type="text"
              name="amount"
              value={amount}
              onChange={handleInputChange}
              autoComplete="off"
            />
            <span>ETH</span>
          </div>

          <div className="form-field-container">
            <span className="form-field-square icon-add-address">
              {addressBookIcon}
            </span>
            <input
              className="form-field"
              placeholder="Recipient"
              type="text"
              name="recipient"
              value={recipient}
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>

          <div className="form-field-container">
            <span className="form-field-square icon-add-time">
              {stopwatchIcon}
            </span>
            <input
              className="form-field"
              placeholder="Timestamp"
              type="text"
              name="date"
              value={date}
              onChange={handleInputChange}
              autoComplete="off"
            />
          </div>

          <div className="button-container">
            <CancelVaultButton></CancelVaultButton>
            <CreateVaultButton></CreateVaultButton>
          </div>
        </form>
      </div>
    </>
  );
}
