import "./CancelVaultButton.css";
import { useNavigate } from "react-router-dom";

export default function CancelVaultButton() {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate("../vault-list")} className="cancel-button">
      Cancel
    </button>
  );
}
