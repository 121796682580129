import { WagmiConfig, createClient } from 'wagmi'
import { getDefaultProvider } from 'ethers'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import "./App.css";
import AddVault from "./AddVault/AddVault";
import VaultList from "./VaultList/VaultList";

const client = createClient({
  autoConnect: true,
  provider: getDefaultProvider(),
})

function App() {
  return (
    <WagmiConfig client={client}>
        <BrowserRouter>
          <div className="app">
            <Navbar />
            <div className="app-container">
              <Routes>
                <Route
                  path="/"
                  element={<Navigate replace to="vault-list" />}
                />
                <Route path="add-vault" element={<AddVault />} />
                <Route path="vault-list" element={<VaultList />} />
                <Route
                  path="*"
                  element={<Navigate replace to="vault-list" />}
                />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
    </WagmiConfig>
  );
}

export default App;
