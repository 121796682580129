// ME TO OTHERS
export const vault1 = {
    amount: 0.1,
    sender: "0x524aD4d7da566383d993073193f81bB596aC6639",
    recipient: "0x9ee8D4d7da56121edb93073193f31bB596aC119a",
    unlockDate: new Date(),
    unlocked: false,
    claimed: false,
}

export const vault2 = {
    amount: 0.02,
    sender: "0x524aD4d7da566383d993073193f81bB596aC6639",
    recipient: "0x9ee8D4d7da56121edb93073193f31bB596aC119a",
    unlockDate: new Date(),
    unlocked: true,
    claimed: false,
}

export const vault3 = {
    amount: 0.003,
    sender: "0x524aD4d7da566383d993073193f81bB596aC6639",
    recipient: "0x9ee8D4d7da56121edb93073193f31bB596aC119a",
    unlockDate: new Date(),
    unlocked: true,
    claimed: true,
}

// OTHERS TO ME
export const vault4 = {
    amount: 1.0,
    sender: "0x9ee8D4d7da56121edb93073193f31bB596aC119a",
    recipient: "0x524aD4d7da566383d993073193f81bB596aC6639",
    unlockDate: new Date(),
    unlocked: false,
    claimed: false,
}

export const vault5 = {
    amount: 20.1,
    sender: "0x9ee8D4d7da56121edb93073193f31bB596aC119a",
    recipient: "0x524aD4d7da566383d993073193f81bB596aC6639",
    unlockDate: new Date(),
    unlocked: true,
    claimed: false,
}

export const vault6 = {
    amount: 30.24,
    sender: "0x9ee8D4d7da56121edb93073193f31bB596aC119a",
    recipient: "0x524aD4d7da566383d993073193f81bB596aC6639",
    unlockDate: new Date(),
    unlocked: true,
    claimed: true,
}