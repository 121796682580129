import "./AddVaultButton.css";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";

export default function AddVaultButton() {

  const plusSquare = <FontAwesomeIcon icon={faPlusSquare} />;

    const navigate = useNavigate();

  return (
   <button onClick={() => navigate('../add-vault')} className="add-button">{plusSquare} <span className="add-vault-button-text">Add Vault</span></button>
  );
}
