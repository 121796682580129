import "./Vault.css";
import Moment from "moment";
import { useAccount } from "wagmi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faWallet,
  faUnlock,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import truncateEthAddress from "truncate-eth-address";

export default function Vault({
  amount,
  sender,
  recipient,
  unlockDate,
  unlocked,
  claimed,
}) {
  const { address } = useAccount();
  const unlockIcon = <FontAwesomeIcon icon={faUnlock} />;
  const walletIcon = <FontAwesomeIcon icon={faWallet} />;
  const infoCircle = <FontAwesomeIcon icon={faInfoCircle} />;
  const leftArrowIcon = <FontAwesomeIcon icon={faArrowLeft} />;
  const rightArrowIcon = <FontAwesomeIcon icon={faArrowRight} />;

  return (
    <div className="vault-container">
      <span className="square">
        {address === sender ? rightArrowIcon : leftArrowIcon}
      </span>

      <p className="vault-address">
        {address === sender
          ? `To: ${truncateEthAddress(recipient)}`
          : `From: ${truncateEthAddress(sender)}`}
      </p>
      <p className="vault-unlock-date">
        Unlock Date: {Moment(unlockDate).format("MMMM Do YYYY, H:mma")}
      </p>
      <span className="spacer"></span>
      {/* SENT */}

      {address === sender && !unlocked && (
        <span className="square icon-unlock">{unlockIcon}</span>
      )}
      {/* RECEIVED */}
      {address === recipient && !unlocked && <span>locked</span>}

      {address === recipient && claimed && <span>claimed</span>}

      {address === recipient && unlocked && !claimed && (
        <span className="square icon-claim">{walletIcon}</span>
      )}

      <span className="icon-eth-amount">{amount} ETH</span>

      <span className="square icon-info">
        <a
          className="icon-info"
          href="https://www.etherscan.io/tx/"
          target="_blank"
          rel="noreferrer"
        >
          {infoCircle}
        </a>
      </span>
    </div>
  );
}
