import "./Navbar.css";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import truncateEthAddress from "truncate-eth-address";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightFromBracket
} from "@fortawesome/free-solid-svg-icons";

export default function Navbar() {
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const { disconnect } = useDisconnect();
  const logoutIcon = <FontAwesomeIcon icon={faRightFromBracket} />;

  return (
    <div className="navbar">
      <img src="crow-mix.svg" alt="logo" />
      <>
        {isConnected && (
          <button className="connect-button" onClick={() => disconnect()}>
            {truncateEthAddress(address)} {logoutIcon}
          </button>
        )}
        {!isConnected && (
          <button className="connect-button" onClick={() => connect()}>Connect Wallet</button>
        )}
      </>
    </div>
  );
}
