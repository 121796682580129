import "./VaultList.css";
import AddVaultButton from "./AddVaultButton/AddVaultButton";
import Vault from "./Vault/Vault";
import { vault1, vault2, vault3, vault4, vault5, vault6 } from "./Data";

export default function AddVault() {
  return (
    <div class="vault-list-container">
      <div className="vault-list-header">
        <h2>Vaults</h2>
        <AddVaultButton />
      </div>

      {false && (
        <>
          <span>All</span>
          <span>Sent</span>
          <span>Received</span>
        </>
      )}

      {false && "Your list of vaults is currently empty."}
      <h3>Sent</h3>
      <Vault {...vault1}></Vault>
      <Vault {...vault2}></Vault>
      <Vault {...vault3}></Vault>
      <h3>Received</h3>
      <Vault {...vault4}></Vault>
      <Vault {...vault5}></Vault>
      <Vault {...vault6}></Vault>
    </div>
  );
}
